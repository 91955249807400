import type { GatsbyBrowser } from 'gatsby';

import { StateProvider } from './src/components/StateStore';
import { BladeProvider } from './src/state/BladeProvider';
import { ModalProvider } from './src/state/ModalProvider';

import './static/fonts/fonts.css';
import { Province, useStore } from './src/store/rootStore';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ModalProvider>
    <BladeProvider>
      <StateProvider>
        {element}
      </StateProvider>
    </BladeProvider>
  </ModalProvider>
);

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps: { location } }) => {
  // dont scroll for faq page
  const disableScroll = location.pathname === '/en/faqs/' || location.pathname === '/fr/faqs/';
  return !disableScroll;
};

function isProvince(s: unknown): s is Province {
  return Object.values(Province).includes(s as Province);
}

const initProvince = async () => {
  if (useStore.getState().province) {
    return;
  }

  try {
    const response = await fetch('/', { method: "OPTIONS" });
    const header = response.headers.get('X-Geo-Info');
    const re = /Province=(?<province>[A-Z]+)/;
    const match = re.exec(header ?? '');
    const province = match?.groups?.province;

    useStore.getState().setProvince((isProvince(province) ? province : Province.ON));
  } catch (e) {
    useStore.getState().setProvince((Province.ON));
  }
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  initProvince();
};
