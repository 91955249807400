module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PC-Insurance","short_name":"PCI","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c851277f2e8bc727ff22cb8502eb4dba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K9WZN3T","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/pcf-engineering/pc-services/pci/pcs-pci-publicsite/src/locales","languages":["en","fr"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/themes/phoenix/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"fr"}],"fields":[{"name":"id","store":true},{"name":"page","store":true,"attributes":{"boost":10}},{"name":"description","store":true,"attributes":{"boost":5}},{"name":"url","store":true,"attributes":{"boost":5}},{"name":"keywords","store":true,"attributes":{"boost":20}},{"name":"category","store":true},{"name":"productType","store":true}],"resolvers":{"ContentfulPage":{},"ContentfulFaqItem":{},"ContentfulAutoRegionalPage":{},"ContentfulHomeRegionalPage":{},"ContentfulBlogPost":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-akamai-bot-manager/gatsby-browser.js'),
      options: {"plugins":[],"whitelist":["pcinsurance.ca"]},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-pcs-cookie-banner/gatsby-browser.js'),
      options: {"plugins":[],"domainScript":"018df558-8235-7895-bb0e-3d61d495d753-test","publicFolder":"/builds/pcf-engineering/pc-services/pci/pcs-pci-publicsite/public","domainList":["www.pcinsurance.ca",".pcinsurance.ca"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
