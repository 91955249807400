import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export enum Province {
  AB = "AB", // Alberta
  BC = "BC", // British Columbia
  MB = "MB", // Manitoba
  NB = "NB", // New Brunswick
  NF = "NF", // Newfoundland and Labrador
  NS = "NS", // Nova Scotia
  NT = "NT", // Northwest Territories
  NU = "NU", // Nunavut Territory
  ON = "ON", // Ontario
  PE = "PE", // Prince Edward Island
  SK = "SK", // Saskatchewan
  YT = "YT", // Yukon Territory
}

export interface RootState {
  province: Province | null;
  setProvince: (province: Province | null) => void
}

const storeInitializer: StateCreator<RootState> = (set) => ({
  province: null,
  setProvince: (province) => set((state) => ({ ...state, province })),
});

export const useStore = create<RootState>()(
  devtools(
    persist(
      storeInitializer,
      {
        name: 'store',
        partialize: (state) => ({ province: state.province }),
      },
    ),
  ),
);
